import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Ссылки к общим ресурсам:
          <br />1. <a
          href="https://disk.yandex.ru/d/p9jerXYyijMtCQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          OneS
        </a>
          <br />2. <a
          href="https://disk.yandex.ru/d/PH7kjdki3RL49K"
          target="_blank"
          rel="noopener noreferrer"
         >
          CSP
         </a>
          <br />3. <a
          href="https://disk.yandex.ru/d/EmJKn12usCzHuA"
          target="_blank"
          rel="noopener noreferrer"
         >
          img (iso)
         </a>
         <hr />
         </p>
      </header>
    </div>
  );
}

export default App;
